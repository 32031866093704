<template>
  <PageWrapper>
    <PagePanel>
      <div class="title">
        <span>{{ $t('menu.transferBetweenAccs') }}</span>
        <svg-icon iconClass="help" className="icon" @click="onTipsTrigger"></svg-icon>
      </div>
      <StatusAlert
        style="margin-bottom: 16px;"
        v-if="tipsVisible"
        @close="onTipsClose"
        showIcon
        type="info"
        :title="$t('transferFunds.pleaseNote')"
      >
        <div class="tips">
          <p>{{ $t('transferFunds.warn1') }}</p>
          <i18n path="transferFunds.warn2" tag="p">
            <template v-slot:mailTo>
              <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
            </template>
          </i18n>
          <p>{{ $t('transferFunds.warn3') }}</p>
        </div>
      </StatusAlert>
      <div class="transfer_fund">
        <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm">
          <el-form-item :label="$t('common.field.accNum')" prop="fromAccount">
            <AccountSelect
              v-model="ruleForm.fromAccount"
              :accountList="formAccountOptions"
              @change="onFromAccountChange"
              data-testid="fromAccount"
            ></AccountSelect>
          </el-form-item>

          <el-form-item :label="$t('common.field.amt')" prop="amount">
            <numeric-input
              showCurrency
              v-model="ruleForm.amount"
              :currency="selected.currentCurrency"
              :precision="2"
            ></numeric-input>
          </el-form-item>

          <el-form-item :label="$t('common.field.accNumTo')" prop="toAccount">
            <AccountSelect
              v-model="ruleForm.toAccount"
              :accountList="toAccountOptions"
              data-testid="toAccount"
            ></AccountSelect>
          </el-form-item>
          <el-form-item>
            <div class="btn_wrapper">
              <el-button type="primary" @click="submitForm" data-testid="submit">
                {{ $t('common.button.submit') }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </PagePanel>

    <SDialog
      :visible.sync="dialog.show"
      :title="$t('common.field.tips')"
      :showFooterCancel="false"
      confirmButtonTestId="confirm"
      @confirm="dialog.show = false"
    >
      <p v-html="dialog.text"></p>
      <template v-if="dialog.type === 'BLACKLISTED'">
        <i18n path="transfer.blackListWarn" tag="p">
          <template v-slot:mailTo>
            <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
          </template>
        </i18n>
      </template>
    </SDialog>
    <SDialog
      :visible.sync="positionDialog.show"
      :title="$t('common.field.tips')"
      :showFooterCancel="positionDialog.showCancel"
      :showFooterConfirm="positionDialog.showConfirm"
      cancelButtonTestId="cancel"
      confirmButtonTestId="confirm"
      @cancel="positionDialog.show = false"
      @confirm="equityConfirm(applyTransfer)"
    >
      <p v-html="positionDialog.text"></p>
      <template v-if="dialog.type === 'BLACKLISTED'">
        <i18n path="transfer.blackListWarn" tag="p">
          <template v-slot:mailTo>
            <a :href="'mailto:' + GLOBAL_CONTACT_EMAIL">{{ GLOBAL_CONTACT_EMAIL }}</a>
          </template>
        </i18n>
      </template>
    </SDialog>
    <CheckDialog
      :isVisible.sync="tradingBonus.checkVisible"
      @onConfirm="applyTransfer"
      :mainMessage="$t('promotion.tradingBonus.endedTip')"
    ></CheckDialog>
  </PageWrapper>
</template>

<script>
import Result from '@/components/Result'
import StatusAlert from '@/components/customUI/StatusAlert.vue'
import NumericInput from '@/components/NumericInput'
import {
  apiGetTransferData_cp,
  apiApplyTransfer_cp,
  apiTransferPermission,
  apiCampaignInfo,
  apiCheckInfoNoBonusCampaign,
} from '@/resource'
import checkPositionMixin from '@/mixins/page/tools/checkPosition'
import promotionTradingBonusMixin from '@/mixins/promotion/tradingBonus'
import blackList from '@/mixins/blackList'
import CheckDialog from '@/components/promotion/common/CheckDialog.vue'
import { getObjectValue } from '@/util/common'
import StatusMessage from '@/components/customUI/StatusMessage.vue'

export default {
  name: 'transferFunds',
  components: { StatusAlert, Result, NumericInput, CheckDialog },
  mixins: [checkPositionMixin, promotionTradingBonusMixin, blackList],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')))
      } else if (parseFloat(value) < 0) {
        callback(new Error(this.$t('common.formValidation.amt0')))
      } else {
        callback()
      }
    }
    return {
      flag: true,
      dialog: {
        show: false,
        text: '',
        type: '',
      },
      formAccountOptions: [],
      toAccountOptions: [],
      selected: {
        fromAccount: '',
        currentCurrency: '',
      },
      ruleForm: {
        amount: '',
        fromAccount: '',
        toAccount: '',
      },
      rules: {
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur',
          },
        ],
        fromAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change',
          },
        ],
        toAccount: [
          {
            required: true,
            message: this.$t('common.formValidation.tradAccReq'),
            trigger: 'change',
          },
        ],
      },
      tipsVisible: false,
    }
  },
  watch: {},
  methods: {
    onFromAccountChange(val) {
      this.ruleForm.toAccount = ''
      this.selected.currentCurrency = val.currency
      this.getToAccounts()
    },
    getToAccounts() {
      this.toAccountOptions = this.formAccountOptions.filter(t => {
        return t.currency == this.selected.currentCurrency && t.login != this.ruleForm.fromAccount
      })
    },
    submitForm() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) this.checkPrerequisiteAndSubmit()
        else return false
      })
    },
    checkPrerequisiteAndSubmit() {
      if (!this.checkTransferBlacklist()) {
        this.transferErrMsg()
      } else {
        apiTransferPermission({ account: this.ruleForm.fromAccount })
          .then(result => {
            if (result.data.data === 'BLACKLISTED') {
              this.dialog = {
                show: true,
                text: '',
                type: 'BLACKLISTED',
              }
            } else if (result.data.data === 'CREDITEXIST') {
              this.dialog = {
                show: true,
                text: this.$t('transfer.creditWarn'),
                type: 'CREDITEXIST',
              }
            } else if (result.data.data === 'PERMITTED') {
              this.checkPosition(this.ruleForm.fromAccount, this.ruleForm.amount, 'transferFunds', () => {
                this.checkActiveCampaign(this.ruleForm.fromAccount, this.ruleForm.toAccount).then(result => {
                  if (result) this.applyTransfer()
                })
              })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    checkActiveCampaign(from, to) {
      return Promise.all([this.getActiveTradingBonusAccount(), apiCheckInfoNoBonusCampaign()]).then(
        ([activeAccount, ndbData]) => {
          // check Trading Bonus
          if (`${activeAccount}` === `${from}` || `${activeAccount}` === `${to}`) {
            this.tradingBonus.checkVisible = true
            return false
          }

          // check No Deposit Bonus
          const activeNDBaccount = getObjectValue(ndbData, 'data.data.mt4AccountContent.mt4_account')

          if (`${activeNDBaccount}` === `${from}` || `${activeNDBaccount}` === `${to}`) {
            this.$message({ message: this.$t('promotion.notification.ndb.cantInerTransfer'), type: 'error' })
            return false
          }
          return true
        },
      )
    },
    async checkTransferBlacklist() {
      if (this.$route.params.passBlacklist) return true
      else
        await this.getTransferBlacklist().then(resp => {
          return resp
        })
    },
    applyTransfer() {
      apiApplyTransfer_cp({ ...this.ruleForm })
        .then(result => {
          if (result.data.code == 0) {
            this.$msgbox({
              title: this.$t('common.field.tips'),
              message: this.$createElement(StatusMessage, { props: { title: this.$t('transferFunds.suc') } }),
              showCancelButton: false,
              confirmButtonText: this.$t('common.button.bkToHm'),
              callback: action => {
                if (action === 'confirm') {
                  this.$router.push({ name: 'home' })
                }
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    fetchTradingAccount() {
      apiGetTransferData_cp()
        .then(resp => {
          if (resp.data.code === 0) {
            this.formAccountOptions = resp.data.data.logins
            if (this.formAccountOptions.length > 0) {
              this.ruleForm.fromAccount = this.formAccountOptions[0].login
              this.selected.currentCurrency = this.formAccountOptions[0].currency
            }
          }
        })
        .catch(err => {
          return this.$message({ message: 'Fetch trading accounts failed.', type: 'error' })
        })
    },
    onTipsClose() {
      window.localStorage.setItem('transferFundsTips', 'true')
      this.tipsVisible = false
    },
    onTipsTrigger() {
      this.tipsVisible = !this.tipsVisible
      if (!this.tipsVisible) {
        window.localStorage.setItem('transferFundsTips', 'true')
      }
    },
  },
  mounted() {
    this.tipsVisible = !window.localStorage.getItem('transferFundsTips')
    this.fetchTradingAccount()
  },
}
</script>

<style lang="scss" scoped>
// @import '@/assets/css/pages/transferFunds.scss';
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $text-primary;
  margin-bottom: 16px;
}
.transfer_fund {
  margin: 0 auto;
  max-width: 392px;

  .btn_wrapper {
    display: flex;
    justify-content: center;
  }
}
.tips p {
  position: relative;
  line-height: 18px;
  padding: 0 10px;
  &::before {
    content: '';
    position: absolute;
    @include rtl-sass-prop(left, right, 0);
    top: 7px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $text-placeholder;
    font-size: 20px;
  }
}
.icon {
  margin: 0 8px;
  cursor: pointer;
}
</style>
